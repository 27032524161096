import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Donorbox } from '../donorbox';

export const DONATION_TYPE = {
  PAC: 'PAC',
  C4: 'C4',
};

const getDonationContent = ({ type }) => {
  let data = {
    en: {
      title: 'Donate to help us win elections',
      subtitle: 'All money raised increases our reach.',
      hint: [
        'We support a more livable, sustainable, and affordable city. We want clean & safe streets, great public schools, well-run transit, a vibrant and thriving economy, and more housing.',
        'Our goal is to help you get informed on local issues, share ways to get engaged, and create change. Many elections in SF end up being decided by dozens of votes: your vote can make the difference.',
        'When more caring, motivated residents get involved in San Francisco, we can build a better city for the long term. We hope you’ll join us.',
      ],
      linkText: 'Email us about large donations',
    },
    zh: {
      title: '捐款幫助我們贏得選舉',
      subtitle: '籌集到的所有資金都擴大了我們的影響力。',
      hint: [
        '我們支持建造一個更宜居、可持續和負擔得起的城市。我們想要乾淨安全的街道、優秀的公立學校、運作良好的交通、充滿活力和繁榮的經濟以及更多的住房。',
        '2022 年是 SF 政治歷史性的一年：GrowSF 幫助罷免了 3 位無能的教育委員會成員，罷免了一位無能的 DA，並選出了兩位有常識的市参事，但我們的工作還沒有完成。',
        '我們的目標是幫助您了解本地問題、分享參與方式並創造變革。 三藩市的許多選舉最終都是由幾十票決定的：你的一票可以產生影響。',
        '我們是一個小團隊，每天都在努力幫助三藩市。我們是三藩市資本效率最高、數據驅動程度最高、透明度最高的政治團體。我們建立了一個人們了解和信任的品牌，我們自己做所有事情（沒有政治顧問），我們使用數據來確保我們能夠轉移選票。',
        '當更多有愛心、積極主動的居民參與到三藩市時，我們就能長期建設一座更好的城市。我們希望您能加入我們。 ',
      ],
      linkText: '向我們發送有關大額捐款的電子郵件',
    },
    es: {
      title: '',
      subtitle: '',
      hint: [],
      linkText: '',
      rulesTitle: '',
      rules: [],
    },
  };

  if (type.toUpperCase() === DONATION_TYPE.PAC) {
    data.en = {
      ...data.en,
      rulesTitle:
        'All donations are publicly disclosed. Contributions are not deductible as charitable contributions for federal income tax purposes. Contribution rules:',
      rules: [
        'I am a U.S. citizen or lawfully admitted permanent resident (i.e., green card holder).',
        'This contribution is made from my own funds, and funds are not being provided to me by another person or entity for the purpose of making this contribution.',
        'I am at least eighteen years old.',
      ],
    };
    data.zh = {
      ...data.zh,
      rulesTitle: '所有捐款均公開披露。 出於聯邦所得稅目的，捐款不得作為慈善捐款扣除。貢獻規則：',
      rules: [
        '我是美國公民或合法承認的永久居民（即綠卡持有者）。',
        '這筆捐款來自我自己的資金，其他人或實體沒有為我提供這筆捐款而提供資金。',
        '我至少十八歲了。',
      ],
    };
  } else if (type.toUpperCase() === DONATION_TYPE.C4) {
    data.en = {
      ...data.en,
      rulesTitle:
        'GrowSF is a 501(c)(4) non-profit. Donations are anonymous and tax exempt, but not deductible as charitable contributions for federal income tax purposes.',
      rules: [],
    };
    data.zh = {
      ...data.zh,
      rulesTitle:
        'GrowSF是一个501(c)(4)非营利组织。捐款是匿名的，可以免税，但不能作为联邦所得税的慈善捐款扣除。除了在线捐款，我们还接受电汇、价值增长的股票和加密货币。',
      rules: [],
    };
  }
  return data;
};

const Campaign = ({ campaignId, currentLang, title, subtitle, type, id }) => {
  // const campaignId = campaign || 'grow-sf-pac';
  //const campaignId = 'grow-sf-pac';

  const data = getDonationContent({ type });
  const currentData = data[currentLang.toLowerCase()];
  return (
    <div className="py-5 relative bg-brand-blue-5">
      <div className="flex flex-col-reverse px-5 py-0 my-0 sm:gap-4 md:py-16 md:mx-auto md:max-w-7xl md:grid md:grid-cols-2 md:items-start md:gap-10 sm:px-10">
        <div className="mx-auto my-5 w-full max-w-sm lg:max-w-md">
          <Donorbox campaign={campaignId} />
        </div>
        <div className="w-full mx-auto flex flex-col gap-1.5 sm:gap-4">
          <div className="flex flex-col gap-1.5 sm:gap-4">
            <p
              {...(id && (id = { id }))}
              className="text-left text-2xl font-bold capitalize text-white pt-2 sm:font-black sm:text-3xl md:text-4xl lg:text-5xl md:pt-0 -mr-2"
            >
              {title || currentData.title}
            </p>
            <p className="text-left text-lg font-bold text-white w-full max-w-2xl sm:text-xl">
              {subtitle || currentData.subtitle}
            </p>
            <p className="flex flex-col text-left text-sm font-light text-brand-blue-1 w-full max-w-2xl gap-1.5 sm:gap-2">
              {currentData.hint.map((hint, index) => (
                <span key={`hint_${index}`}>{hint}</span>
              ))}
            </p>
          </div>
          <div className="hidden flex-col gap-5 md:gap-4 md:flex">
            <div className="flex flex-col gap-4 mx-auto w-full max-w-sm md:w-80 md:mx-0 md:pr-5">
              <OutboundLink
                href="mailto:contact@growsf.org"
                className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 border border-brand-green-3 text-brand-green-3 bg-transparent text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100"
              >
                {currentData.linkText}
              </OutboundLink>
              <OutboundLink
                href="https://pay.daimo.com/donate/growsf"
                className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 border border-brand-green-3 text-brand-green-3 bg-transparent text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100"
              >
                Donate Crypto
              </OutboundLink>
            </div>
            <p className="text-left text-sm italic font-light mx-auto text-brand-blue-1">
              {currentData.rulesTitle}
            </p>
            {currentData.rules !== undefined && currentData.rules.length > 0 && (
              <ul className="flex flex-col gap-2 list-decimal pl-4">
                {currentData.rules.map((rule, index) => (
                  <li
                    key={`rule_${index}`}
                    className="text-left text-sm italic font-light text-brand-blue-1"
                  >
                    {rule}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="px-5 flex flex-col gap-5 md:gap-4 md:hidden">
        <div className="flex flex-col gap-4 mx-auto w-full max-w-sm md:w-80 md:mx-0 md:pr-5">
          <OutboundLink
            href="mailto:contact@growsf.org"
            className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 border border-brand-green-3 text-brand-green-3 bg-transparent text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100"
          >
            {currentData.linkText}
          </OutboundLink>
          <OutboundLink
            href="https://pay.daimo.com/donate/growsf"
            className="flex justify-center items-center w-full px-5 py-3.5 font-bold rounded-md bg-pos-0 scale-100 border border-brand-green-3 text-brand-green-3 bg-transparent text-xs transition duration-200 uppercase hover:bg-gradient-to-r hover:from-brand-blue-4 hover:via-brand-blue-4 hover:to-brand-green-3 hover:bg-size-200 hover:border-transparent hover:bg-pos-100 hover:text-white hover:scale-105 focus:bg-brand-blue-5 focus:border-white focus:text-white focus:bg-none focus:scale-100"
          >
            Donate crypto
          </OutboundLink>
        </div>
        <p className="text-left text-sm italic font-light mx-auto text-brand-blue-1">
          {currentData.rulesTitle}
        </p>
        {currentData.rules !== undefined && currentData.rules.length > 0 && (
          <ul className="flex flex-col gap-2 list-decimal pl-4">
            {currentData.rules.map((rule, index) => (
              <li
                key={`rule_${index}`}
                className="text-left text-sm italic font-light text-brand-blue-1"
              >
                {rule}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Campaign;
