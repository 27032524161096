import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import ReactPlayer from 'react-player/youtube';
import Campaign from '../components/content/campaign';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Carousel from '../components/layout/carousel';
import Profile from '../components/content/profile';

import go from '../images/icons/icon-link-chevron-green.svg';
import TopCampaign from '../components/content/topCampaign';

const DonatePage = ({ data, location }) => {
  const donate = getImage(data.donate.childImageSharp);
  const SachinProfile = getImage(data.SachinProfile.childImageSharp);
  const CristinaProfile = getImage(data.CristinaProfile.childImageSharp);
  const HunterProfile = getImage(data.HunterProfile.childImageSharp);
  const DumpDean = getImage(data.DumpDean.childImageSharp);
  const SupervisorMap = getImage(data.SupervisorMap.childImageSharp);

  const list = [
    {
      description:
        "My wife and I moved here in the 90's and are raising a family in the city. I might not be a native, but I'm a proud local, and I support GrowSF because they're focused on positive change: helping business owners thrive, more affordable housing, and supervisors who care about quality of life.",
      name: 'Hunter Walk',
      role: 'Partner at Homebrew',
      image: HunterProfile,
    },
    {
      description:
        "I've been most impressed with the impact GrowSF had on the winning campaigns for school board and Joel Engardio in D4–and specifically with the outcomes in making the school board and board of supervisors more accurately reflect the desires of SF voters.",
      name: 'Cristina Cordova',
      role: 'COO at Linear',
      image: CristinaProfile,
    },
    {
      description:
        "I love San Francisco, and I want to raise my kids here. That's why I left my career in tech to start GrowSF and work every day to get San Francisco on the right track. I'm not leaving. This is the most incredible city in the world, and I want more people to live here.",
      name: 'Sachin Agarwal',
      role: 'Co-founder at GrowSF',
      image: SachinProfile,
    },
  ];

  return (
    <Layout>
      <SEO
        title="Donate to GrowSF"
        description="Your donation will help San Francisco's future!"
        pathname={location.pathname}
        image={data?.opengraph?.childImageSharp.resize || null}
      />
      {/* Begin Top Campaign */}
      <section>
        <TopCampaign
          campaignId="donate-to-grow-sf"
          currentLang="en"
          title="Donate to GrowSF"
          subtitle="GrowSF is the #1 voter guide in San Francisco, trusted by hundreds of thousands of residents. We are working towards a vibrant city where new residents are welcomed, businesses can flourish, and families can put down their roots and thrive. Donate here to support outcomes over ideologies."
          type="C4"
        />
      </section>
      {/* End Top Campaign */}
      {/* Begin Carousel */}
      <section>
        <div className="relative flex flex-col-reverse overflow-hidden py-20 min-h-[675px] lg:flex-col lg:mt-0">
          <div className="absolute inset-0">
            <GatsbyImage
              image={donate}
              loading="lazy"
              objectFit="cover"
              alt="A view of San Francisco from Dolores Park"
              className="z-0 h-full"
            />
          </div>
          <div className="z-10 absolute inset-0 bg-[linear-gradient(318.83deg,#FEDC6B_1.66%,#64D09C_65%)] opacity-50"></div>
          <div className="relative z-20 my-auto">
            <Carousel
              className="!static !px-5 max-w-6xl mr-0 [&_.swiper-slide-next]:[transform:scale(0.9)_translateZ(0px)] [&_.swiper-button-prev]:cursor-pointer [&_.swiper-button-prev]:absolute [&_.swiper-button-prev]:-bottom-12 [&_.swiper-button-prev]:left-1/2 [&_.swiper-button-prev]:-translate-x-[calc(50%_+_25px)] [&_.swiper-button-prev]:bg-[url('../images/icons/prev.svg')] [&_.swiper-button-prev]:bg-no-repeat [&_.swiper-button-prev]:bg-center [&_.swiper-button-prev]:w-10 [&_.swiper-button-prev]:h-10 [&_.swiper-button-prev]:bg-brand-blue-5 [&_.swiper-button-prev]:rounded-full [&_.swiper-button-disabled]:opacity-75 [&_.swiper-button-disabled]:!cursor-not-allowed [&_.swiper-button-next]:cursor-pointer [&_.swiper-button-next]:absolute [&_.swiper-button-next]:-bottom-12 [&_.swiper-button-next]:left-1/2 [&_.swiper-button-next]:-translate-x-[calc(50%_-_25px)] [&_.swiper-button-next]:bg-[url('../images/icons/next.svg')] [&_.swiper-button-next]:bg-no-repeat [&_.swiper-button-next]:bg-center [&_.swiper-button-next]:w-10 [&_.swiper-button-next]:h-10 [&_.swiper-button-next]:bg-brand-blue-5 [&_.swiper-button-next]:rounded-full sm:[&_.swiper-slide-next]:[transform:scale(0.85)_translateZ(0px)] sm:[&_.swiper-button-prev]:translate-y-full sm:[&_.swiper-button-next]:translate-y-full"
              childClassName="py-5 self-center duration-500"
              navigation
              spaceBetween={20}
              slidesPerView={1.15}
              breakpoints={{
                640: {
                  slidesPerView: 1.3,
                },
              }}
              autoplay
              loop
            >
              {list?.map((item, index) => (
                <div key={index} className="bg-white p-5 sm:p-12">
                  <blockquote className="relative text-brand-blue-4 pt-1 text-base font-light border-l-0 !pl-0 before:absolute before:block before:content-[''] before:bg-[url('../images/icons/quote.svg')] before:w-16 before:h-12 before:-translate-x-2 sm:before:-translate-x-1/4 sm:before:-translate-y-1/2 sm:pt-0">
                    <span className="relative">{item.description}</span>
                  </blockquote>
                  <Profile className="mt-4 [&_.role]:uppercase" name={item.name} role={item.role}>
                    <GatsbyImage className="w-14 h-14" image={item.image} alt={item.name} />
                  </Profile>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
      {/* End Carousel */}
      {/* Begin embed Youtube video */}
      <section>
        <div className="bg-brand-blue-5 px-5 flex justify-center pt-20 pb-12 sm:pb-0 min-h-96">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=q8veJeGSNec"
            config={{
              youtube: {
                playerVars: { controls: 1, showinfo: 1 },
              },
            }}
            className="rounded-md overflow-hidden isolate w-full max-w-full max-h-60 sm:w-auto sm:max-h-none"
          />
        </div>
      </section>
      {/* End embed Youtube video */}
      {/* Begin bottom Campaign */}
      <Campaign
        campaignId="donate-to-grow-sf"
        currentLang="en"
        title="GrowSF pursues common sense solutions for our city"
        subtitle="All money raised supports our 501(c)(4) programs"
        type="C4"
      />
      {/* End bottom Campaign */}
    </Layout>
  );
};
export default DonatePage;

export const query = graphql`
  query {
    opengraph: file(relativePath: { eq: "donate/donate_opengraph.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData
        resize(width: 1200) {
          src
          height
          width
        }
      }
    }
    donate: file(relativePath: { eq: "donate/donate.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    SachinProfile: file(relativePath: { eq: "donate/sachin_profile.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    HunterProfile: file(relativePath: { eq: "donate/hunter_profile.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    CristinaProfile: file(relativePath: { eq: "donate/cristina_profile.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: FULL_WIDTH
          formats: [WEBP, AUTO]
          breakpoints: [400, 800, 1200, 1400]
        )
      }
    }
    DumpDean: file(relativePath: { eq: "dumpdean/dump-dean-2.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
    SupervisorMap: file(relativePath: { eq: "sf-supervisor-map/sf-supervisor-district-map.png" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          formats: [WEBP, AUTO]
          width: 400
          sizes: "400"
        )
      }
    }
  }
`;
